import Timeout from 'smart-timeout';
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ "slide" ]

  initialize() {
    this.showSlide(0)
    this.play()
  }

  next() {
    if (this.index < this.slideTargets.length - 1) {
      this.showSlide(this.index + 1) 
    } else {
      this.showSlide(0)
    }
  }

  previous() {
    if (this.index > 1) {
      this.showSlide(this.index - 1)
    } else {
      this.showSlide(this.slideTargets.length - 1)
    }
  }

  showSlide(index) {
    this.index = index
    this.slideTargets.forEach((el, i) => {
      el.classList.toggle("slide--current", index == i)
    })
  }

  play() {
    this.refreshTimer = setInterval(() => { this.next() }, 3000)
  }

}
