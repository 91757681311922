import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "container"]

  connect() {
  }

  goPage(event) {
    let [data, status, xhr] = event.detail
    this.containerTarget.innerHTML = xhr.response
    MathJax.typeset();
  }

  toggleMenu(event) {
    console.log(event.target.dataset.id)
    document.getElementById(event.target.dataset.id).classList.toggle('is-open')
  }
}



