window.MathJax = {
  options: {
    skipHtmlTags: [ 
        'script', 'noscript', 'style', 'textarea', 'pre',
        'code', 'annotation', 'annotation-xml', 'trix-editor'
    ]
  },
  tex: {
    inlineMath: [['$', '$'], ['\\(', '\\)']]
  },
  svg: {
    fontCache: 'global'
  }
};

(function () {
  var script = document.createElement('script');
  script.src = 'https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-svg.js';
  script.async = true;
  document.head.appendChild(script);
})();
