//var Trix  = require("trix")
//Trix.config.textAttributes.td = {tagName: 'td'}
//Trix.config.blockAttributes.table = {tagName: 'table'}
//Trix.config.blockAttributes.thead = {tagName: 'thead'}
//Trix.config.blockAttributes.tbody = {tagName: 'tbody'}
//Trix.config.blockAttributes.tr = {tagName: 'tr'}
//Trix.config.blockAttributes.td = {tagName: 'td', group: true}

window.addEventListener("trix-file-accept", function(event) {
  const acceptedTypes = ['image/jpeg', 'image/png', 'image/gif']
  if (!acceptedTypes.includes(event.file.type)) {
    event.preventDefault()
    alert("Only support attachment of jpeg, gif, or png files")
  }
  const maxFileSize = 1024 * 1024 // 1MB
  if (event.file.size > maxFileSize) {
    event.preventDefault()
    alert("Only support attachment files upto size 1MB!")
  }
})


