// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"
import SlimSelect from 'slim-select'

export default class extends Controller {
  static targets = [ 'simple', 'user', 'istitute' ]

  connect() {
    if (this.hasSimpleTarget) {this.simpleTargets.forEach(element => this.simple(element))}
    if (this.hasUserTarget) {this.userTargets.forEach(element => this.user(element))}
    if (this.hasIstituteTarget) {this.istituteTargets.forEach(element => this.istitute(element))}
  }

  simple(element) {
    new SlimSelect({ select: element })
  }

  user(element) {
    new SlimSelect({
      select: element,
      placeholder: 'Seleziona un docente',
      searchingText: 'Sto cercando...',
      searchPlaceholder:  'Digita per ricercare',
      searchText: 'Nessun risultato',
      allowDeselect: true,
      ajax: function (search, callback) {
        // Check search value. If you dont like it callback(false) or callback('Message String')
        if (search.length < 3) {
          callback('Inserire almeno 3 caratteri')
          return
        }

        // Perform your own ajax request here
        fetch(`/users/list.json?text=${search}`)
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          let data = []
          for (let i = 0; i < json.users.length; i++) {
            data.push({text: `${json.users[i].name} ${json.users[i].surname}`, value: json.users[i].id})
          }

          // Upon successful fetch send data to callback function.
          // Be sure to send data back in the proper format.
          // Refer to the method setData for examples of proper format.
          callback(data)
        })
        .catch(function(error) {
          // If any erros happened send false back through the callback
          callback(false)
        })
      }
    })
  }

  istitute(element) {
    new SlimSelect({
      select: element,
      placeholder: 'Seleziona un istituto',
      searchingText: 'Sto cercando...',
      searchPlaceholder:  'Digita per ricercare',
      searchText: 'Nessun risultato',
      ajax: function (search, callback) {
        // Check search value. If you dont like it callback(false) or callback('Message String')
        if (search.length < 3) {
          callback('Inserire almeno 3 caratteri')
          return
        }

        // Perform your own ajax request here
        fetch(`/istitutes/list.json?text=${search}&list=1`)
        .then(function (response) {
          return response.json()
        })
        .then(function (json) {
          let data = []
          for (let i = 0; i < json.istitutes.length; i++) {
            data.push({text: json.istitutes[i].title, value: json.istitutes[i].id})
          }

          // Upon successful fetch send data to callback function.
          // Be sure to send data back in the proper format.
          // Refer to the method setData for examples of proper format.
          callback(data)
        })
        .catch(function(error) {
          // If any erros happened send false back through the callback
          callback(false)
        })
      }
    })
  }
}
