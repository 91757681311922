// Visit The Stimulus Handbook for more details 
// https://stimulusjs.org/handbook/introduction
// 
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'content', 'template' ]

  add(event) {
    console.log(this.contentTarget)
    event.preventDefault()
    let time = new Date().getTime()
    let entry = this.contentTarget.appendChild(this.templateTarget.content.firstChild.cloneNode(true))
    let index = entry.getAttribute('data-index')
    entry.querySelectorAll('[name]').forEach( (field) => { 
      field.setAttribute('name', field.getAttribute('name').replace(`[${index}]`, `[${time}]`))
      field.setAttribute('id', field.getAttribute('name').replace(`[${index}]`, `[${time}]`))
    } )
    entry.querySelectorAll('[for]').forEach( (field) => { 
      field.setAttribute('for', field.getAttribute('for').replace(`_${index}_`, `_${time}_`))
    } )   
  }

  destroy(event) {
    event.preventDefault()
    let entry = event.target.closest("[data-target='nested.entry']")
    entry.classList.add("hide")
    entry.querySelector("[data-target='nested.destroy']").value = 1
  }

}
